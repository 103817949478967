<template>
  <div>
    <work-view-detail :result="result" :is-show-loading="isShowLoading"/>

    <b-overlay :show="isShowLoading">
      <validation-observer ref="validateForm" #default="{invalid}">
        <b-form @submit.prevent="saveData">
          <b-card :title="headerSafety">
            <div class="text-center mb-2" v-if="!isCrossCheck">
              <b-form-group>
                {{ showCheckTypeName }}
<!--                <b-form-radio-group v-model="safetyCheckType" :options="optionCheckType"/>-->
              </b-form-group>
            </div>

            <b-row v-for="(row, key) in safetyCheckLists" :key="'safety'+key" class="safety-row">
              <b-col cols="9">{{ row.safetyName }}</b-col>
              <b-col cols="3" class="text-center">
                <b-form-checkbox checked="true" v-model="row.safetyPass" class="custom-control-success" name="check-button" switch>
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon"/>
                  </span>
                        <span class="switch-icon-right">
                    <feather-icon icon="XIcon"/>
                  </span>
                </b-form-checkbox>
              </b-col>
              <b-col md="6" class="mt-1">
                <b-form-group :label="row.safetyPass ? $t('work.photo') : $t('work.photo') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('work.photo')" :rules="row.safetyPass ? '' : 'required'">
                    <b-form-file v-model="row.safetyFile" accept=".jpg, .png, .gif, .mp4, .mov" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mt-1">
                <b-form-group :label="row.safetyPass ? $t('work.approveComment') : $t('work.approveComment') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('work.approveComment')" :rules="row.safetyPass ? '' : 'required'">
                    <b-form-input v-model="row.safetyComment" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-row v-if="!isShowLoading">
            <b-col class="text-center">
              <b-button type="submit" variant="success" class="my-1" :disabled="isBtnDisabled">
                <b-spinner small v-if="isBtnDisabled"/>
                <feather-icon icon="SaveIcon" v-else/>
                {{ $t('general.btnSubmit') }}
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BButton, BForm, BFormFile, BFormRadioGroup, BFormCheckbox, BOverlay} from 'bootstrap-vue'
import {required} from '@validations'
import formMixins from "@/mixins/formMixins"
import WorkViewDetail from "@/components/work/WorkViewDetail"
import {WorkService, FileStorageService} from "@/services"
import masterMixins from "@/mixins/masterMixins"

const workService = new WorkService()
const fileStorageService = new FileStorageService()

export default {
  name: 'WorkFormSafetyCheck',
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormFile,
    BFormRadioGroup,
    BFormCheckbox,
    BOverlay,
    WorkViewDetail,
  },
  mixins: [masterMixins, formMixins],
  async created() {
    this.id = this.$route.params.id
    await this.getData()
    let workTypeResult = await this.getWorkType(this.result.workTypeId)
    this.safetyCheckLists = workTypeResult.safetyCheckLists.map(safety => ({...safety, safetyPass: false, safetyFile: [], safetyComment: ''}))

    this.optionCheckType.push({text: this.$t('work.radioCheckBefore'), value: 'B'})
    this.optionCheckType.push({text: this.$t('work.radioCheckAfter'), value: 'A'})

    if(this.$route.name === 'pageWorkSafetyCrossCheck') {
      this.isCrossCheck = true
      this.safetyCheckBy = 2
    }

    if(this.$route.query.type === 'during') {
      this.safetyCheckType = 'B'
    } else if(this.$route.query.type === 'after') {
      this.safetyCheckType = 'A'
    }

    this.enabledCheckBackButton()
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    id: '',
    result: {},
    safetyCheckBy: 1,
    safetyCheckType: 'B',
    isCrossCheck: false,
    optionCheckType: [],
    safetyCheckLists: []
  }),
  computed: {
    headerSafety() {
      return this.isCrossCheck ? this.$t('work.headerSafetyCrossCheck') : this.$t('work.headerSafetyCheck')
    },
    showCheckTypeName() {
      let result = this.optionCheckType.find(option => option.value === this.safetyCheckType)
      return result ? result.text : ''
    }
  },
  methods: {
    async getData() {
      let queryResult = await workService.getData(this.id)
      if (queryResult.isSuccess) {
        let {data} = queryResult
        let {result} = data
        this.result = result

        this.isShowLoading = false
      } else {
        this.$store.commit('main/setToastError', queryResult.data)
        await this.$router.push({name: 'pageWork'})
      }
    },
    saveData() {
      this.$refs.validateForm.validate().then(async success => {
        if (success) {
          let checkLists = []
          await Promise.all(
              this.safetyCheckLists.map(async data => {
                let row = {
                  safetyComment: data.safetyComment,
                  safetyId: data.safetyId,
                  safetyName: data.safetyName,
                  safetyPass: data.safetyPass,
                }

                if (Object.entries(data.safetyFile).length > 0) {
                  let uploadResult = await fileStorageService.upload(data.safetyFile)
                  row.photoId = uploadResult.fileId
                }
                checkLists.push(row)
              })
          )

          let actionData = {
            safetyCheckBy: this.safetyCheckBy,
            safetyCheckType: this.safetyCheckType,
            safetyCheck: checkLists,
          }
          workService.safetyCheck(this.id, actionData)
              .then(({isSuccess, data}) => {
                this.isBtnDisabled = false
                if (isSuccess) {
                  this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
                  this.$router.replace({name: 'pageWork'})
                } else {
                  this.$store.commit('main/setToastError', data)
                }
              })
        }
      })
    },
  }
}
</script>

<style lang="scss">
.safety-row {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e5e5e5;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f5f5f5;
  }
}
</style>
